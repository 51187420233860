import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../services/user/user.service";

export const getWorlds = createAsyncThunk(
  "worlds/getWorlds",
  async (_, { getState }) => {
    let data = getState().worlds.worlds;
    if (data == null) {
      try {
        const res = await UserService.getWorlds();
        if (res.error != null) {
          data = null;
        } else if (res.data != null) {
          data = res.data;
        }
      } catch (err) {
        console.log("[worlds/getWorlds] ERR:", err);
      }
    }
    return data;
  }
);

const worldsSlice = createSlice({
  name: "worlds",
  initialState: {
    worlds: null,
    currentWorld: null,
  },
  reducers: {
    setCurrentWorld: (state, action) => {
      state.currentWorld = action.payload;
    },
  },
  extraReducers(build) {
    build.addCase(getWorlds.fulfilled, (state, action) => {
      state.worlds = action.payload;
    });
  },
});

export const { setCurrentWorld } = worldsSlice.actions;

export const selectWorlds = ({ worlds }) => worlds.worlds;
export const selectCurrentWorld = ({ worlds }) => worlds.currentWorld;

export default worldsSlice.reducer;
