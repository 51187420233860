import React from "react";
import { selectCurrentWorld } from "../../store/worldSlice";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./demo.css";
import UserService from "../../services/user/user.service";

class Demo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      personaHandle: this.props.currentWorld?.personaHandle,
      handle: this.props.currentWorld?.handle,
    };
  }

  componentDidMount() {
    this.appInit();
  }

  componentWillUnmount() {
    if (window.myGameCastStream?.close != null) {
      window.myGameCastStream.close();
    }
  }

  refreshWorld() {
    if (window.myGameCastStream?.close != null) {
      window.myGameCastStream.close();
    }
    this.appInit();
  }

  appInit = () => {
    // Use Keyboard API to set a "long hold" escape from fullscreen
    // if the browser supports this API (note that Safari does not)
    if ("keyboard" in window.navigator && "lock" in window.navigator.keyboard) {
      document.addEventListener("fullscreenchange", () => {
        if (document.fullscreenElement) {
          window.navigator.keyboard.lock(["Escape"]);
        }
      });
    }

    // eslint-disable-next-line no-undef
    window.myGameCastStream = new gamecastsdk.GameCast({
      videoElement: document.getElementById("streamVideoElement"),
      inputConfiguration: {
        autoMouse: true,
        autoKeyboard: true,
        autoGamepad: true,
        hapticFeedback: true,
        setCursor: "visibility",
        autoPointerLock: "fullscreen",
      },
      clientConnection: {
        connectionState: (state) => {
          console.log("Connection state: " + state);
          if (state === "disconnected") {
            this.appDisconnect();
          }
          // if (state === "connecting") {
          //   const progress = document.querySelector(".progress");
          //   progress.style.setProperty(
          //     "--done-percent",
          //     (Math.min(counter++, 6) / 6) * 100
          //   );
          // }
        },
        channelError: (error) => {
          console.log("Connection error:", JSON.stringify(error));
        },
      },
    });

    this.appStartStreaming();
  };

  appStartStreaming = async () => {
    this.counter = 1;
    // Required to setup TOUCH controls
    // setup(document.querySelector("#mobile-controls"));

    await this.appEnableMic();
    try {
      this.appShowPanel("loader");

      // Generate the signal request for a new WebRTC connection
      const signalRequest =
        await window.myGameCastStream.generateSignalRequest();

      // Initiate the connection attempt via our backend server API
      const token = await UserService.createStreamSession({
        SignalRequest: signalRequest,
        world: `${this.state.personaHandle}/${this.state.handle}`,
      });

      // Loop of sleeping for 1 second, then polling GetSignalResponse
      // (not infinite, eventually it will succeed or doPost will throw)
      let signalResponse = "";
      while (signalResponse != null && signalResponse.length === 0) {
        console.log("Waiting...");
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        signalResponse = (await UserService.getSignalResponse(token))
          ?.SignalResponse;

        const progress = document.querySelector(".progress");
        progress?.style.setProperty(
          "--done-percent",
          (Math.min(this.counter++, 28) / 28) * 100
        );
      }
      if (signalResponse != null && signalResponse !== "") {
        // Complete connection by forwarding signal response to GameCast object
        await window.myGameCastStream.processSignalResponse(signalResponse);
        this.appShowPanel("appStreaming");

        await new Promise((resolve) => {
          setTimeout(resolve, 4200);
        });
        // after connected to streaming attach input
        //appToggleInput();
        window.myGameCastStream.attachInput();
        // this.refreshWorld();
        document.getElementById("streamVideoElement").focus();
      } else {
        window.myGameCastStream.close();
        this.refreshWorld();
        // document.getElementById("appError").style.display = "flex";
      }
    } catch (e) {
      console.log("ERROR:", e);
      window.myGameCastStream.close();
      var appError = document.getElementById("appError")

      if (appError != null){
        appError.style.display = 'flex';
      }
      // document.getElementById("appError").style.display = "flex";
    }
  };

  appShowPanel = (id) => {
    for (const iter of [
      "loader",
      "appStreaming",
      "appDisconnected",
      "appError",
    ]) {
      var iterElement = document.getElementById(iter)
      if(iterElement != null){
        iterElement.style.display = id === iter ? "flex" : "none";
      }
      // document.getElementById(iter)?.style.display =
      //   id === iter ? "flex" : "none";
    }
  };

  appEnableMic = async () => {
    if (window.myMicEnabling || window.myMicEnabled) {
      return;
    }

    window.myMicEnabling = true;
    try {
      await window.myGameCastStream.enableMicrophone();
      window.myMicEnabled = true;
    } catch (e) {
      // Handle DOMException, prompt user if microphone is necessary
    } finally {
      window.myMicEnabling = false;
    }
  };

  appDisconnect = () => {
    window.myGameCastStream.close();
    this.appShowPanel("appDisconnected");
  };

  reloadWindow = () => {
    window.location.reload();
    return false;
  };

  videoFocus = (e) => {
    e?.target?.focus();
  };

  render() {
    return (
      <>
        <div id="loader" style={{ display: "none" }}>
          <img
            src="https://sansar.com/assets/images/homepage/Logo_Small.svg"
            alt=""
            width="258"
          />
          <div className="loader" />
          <div className="message">
            Join Sansar as a full member by creating an account here:{" "}
            <p>https://account.sansar.com/register</p>
          </div>
          <div className="progress">
            <div className="done" />
          </div>
          <div id="appError" style={{ display: "none" }}>
            <h1>Stream Error</h1>
            <p>Not able to initiate streaming.</p>
            <p onClick={this.reloadWindow}>Click to reload window</p>
          </div>
        </div>

        <div id="appStreaming" style={{ display: "none" }}>
          <div id="desktop-controls">
            <ul>
              <li>
                Use W,A,S,D keys to <b>move around</b>
              </li>
              <li>
                Mouse right click to <b>look around</b>
              </li>
              <li>
                Mouse wheel to <b>zoom in/out</b>
              </li>
              <li>
                Keys 1-6 to trigger <b>“emotes”</b>
              </li>
              <li>
                Space bar to <b>jump</b>
              </li>
            </ul>
          </div>
          <div id="mobile-controls" data-control="look">
            <div data-control="move" />
            <div data-control="jump" />
            <div data-control="primary" />
            <div data-control="seconday" />
            <div data-control="run" />
            <div data-control="crouch" />
            <div data-control="voice" />
            <div data-control="emotes" />
            <div className="emotes">
              <div data-control="1">1</div>
              <div data-control="2">2</div>
              <div data-control="3">3</div>
              <div data-control="4">4</div>
              <div data-control="5">5</div>
              <div data-control="6">6</div>
              <div data-control="7">7</div>
              <div data-control="8">8</div>
              <div data-control="9">9</div>
            </div>
          </div>
          <div id="streamFullscreenContainer">
            <div id="streamFullscreenOverlay">&nbsp;</div>
            <video
              id="streamVideoElement"
              onClick={this.videoFocus}
              autoPlay
              playsInline
              disablePictureInPicture
            />
          </div>
        </div>

        <div id="appDisconnected" style={{ display: "none" }}>
          <h1>Stream Disconnected</h1>
          <p href="#" onClick={this.reloadWindow}>
            Click to reload window
          </p>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentWorld: selectCurrentWorld(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
