const URL = "https://stage.myciright.com/Ciright";

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",
    USER_PROFILE:
      URL +
      "/ajaxCall-photo.htm?flag=employeePhoto&compress=0&colorCode=veuit&id=",
    SUBSCRIPTION_ID: 8283837,
    VERTICAL_ID: 2603,
    APP_ID: 2779,
    SPHERE_TYPE_URL: "sansar-lg-tv-app.htm",
  },
};

export default AppConfig;
