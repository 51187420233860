import "./Worlds.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import WorldItem from "../../components/WorldItem/WorldItem.js";
import { useDispatch, useSelector } from "react-redux";
import { getWorlds, selectWorlds } from "../../store/worldSlice.js";
import { setEmployeeData } from "../../store/NavigationSlice.js";
import { useNavigate } from "react-router";
import { Backdrop, CircularProgress } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { IconContext } from "react-icons";

// const filters = ["All", "Popular", "Visited", "My Worlds", "Favorites"];

const Worlds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const worlds = useSelector(selectWorlds);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    setLoading(true);
    dispatch(getWorlds()).then(() => setLoading(false));
  }, [dispatch]);

  const logOut = useCallback(() => {
    window.localStorage.clear();
    dispatch(setEmployeeData(null));
    navigate("/signin", { replace: true });
  }, [dispatch, navigate]);

  const filtered = useMemo(
    () =>
      worlds?.filter(
        (world) =>
          world.name.toLowerCase().includes(searchText.trim().toLowerCase()) ||
          world.personaName
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
      ),
    [searchText, worlds]
  );

  return (
    <div className="worldsPage">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="worldsHeader">
        <img
          src={require("../../assests/Images/Logo_500x163.png")}
          className="worldsHeaderLogo"
          alt="Sansar Logo"
        />
      </div>
      <div className="worldsBody">
        <div className="wrap">
          <div className="title">Worlds </div>
          <div>
            <button className="logout-button" onClick={logOut}>
              Logout
            </button>
          </div>
        </div>
        <div>
          <hr className="hrLine" />
        </div>
        <div className="search">
          <p
            style={{
              fontSize: "16px",
              verticalAlign: "center",
              opacity: "0.5",
              fontFamily: "Roboto",
            }}
          >
            {"I'm Searching for..."}
          </p>
          <div className="filtersWrap">
            <div className="searchBar">
              <IconContext.Provider value={{ color: "#fff", size: "2em" }}>
                <FaSearch
                  style={{ position: "absolute", paddingLeft: "0.5rem" }}
                />
              </IconContext.Provider>
              <input
                className="searchInput"
                type="text"
                placeholder="Search All"
                value={searchText}
                onChange={onChangeSearch}
              />
            </div>
          </div>
        </div>
        <div className="worldWraper">
          {filtered != null
            ? filtered.map((world) => (
                <WorldItem key={world.id} world={world} />
              ))
            : "No Worlds"}
        </div>
      </div>
    </div>
  );
};

export default Worlds;
