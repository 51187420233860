import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "../services/user/user.service";
import AppConfig from "../config/app.config";

export const signIn = createAsyncThunk(
  "navigation/signIn",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await UserService.signIn({
        subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
        verticalId: AppConfig.API.VERTICAL_ID,
        username: payload.username,
        password: payload.password,
        appId: AppConfig.API.APP_ID,
        sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
      });
      if (res.error) {
        return rejectWithValue(res.error?.response?.data ?? "Error Signing in");
      } else if (res.status) {
        const employeeData = res?.data[0]?.employees[0];
        if (employeeData != null) {
          return fulfillWithValue(employeeData);
        } else {
          return rejectWithValue("Error Signing in");
        }
      } else {
        return rejectWithValue(res.message ?? "Error Signing in");
      }
    } catch (err) {
      return rejectWithValue("Error Signing in");
    }
  }
);

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    employeeData: null,
  },
  reducers: {
    setEmployeeData: (state, action) => {
      state.employeeData = action.payload;
    },
  },
});

export const { setEmployeeData } = navigationSlice.actions;

export const selectEmployeeData = ({ navigation }) => navigation.employeeData;

export default navigationSlice.reducer;
