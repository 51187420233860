import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { setCurrentWorld } from "../../store/worldSlice";
import { useNavigate } from "react-router";
import "./WorldItem.css";

const WorldItem = ({ world }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _navigateTo = useCallback(() => {
    dispatch(setCurrentWorld(world));
    navigate("/demo");
  }, [navigate, dispatch, world]);

  return world != null ? (
    <div className="world-card" onClick={_navigateTo}>
      <img
        className="card-image"
        alt={world.name ?? "World"}
        src={
          world.image.sizes.find((x) => x.size === "grid")?.url ??
          world.image.url
        }
      />
      <div className="card-image-container">
        <div className="world-name">{world.name}</div>
        <div className="world-persona">{world.personaName}</div>
      </div>
    </div>
  ) : null;
};

export default WorldItem;
