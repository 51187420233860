import { useCallback, useState } from "react";
import "./Signin.css";
import { useDispatch } from "react-redux";
import { setEmployeeData, signIn } from "../../store/NavigationSlice";
import validator from "validator";
import { useNavigate } from "react-router";
import { Backdrop, CircularProgress } from "@mui/material";
import { NotificationManager } from "react-notifications";

export const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);

  const onEmailChange = useCallback(
    (e) => setEmail(e.target.value.split(" ").join("")),
    []
  );
  const onPasswordChange = useCallback((e) => setPass(e.target.value), []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (email === "") {
        NotificationManager.error("", "Please Enter Email", 3000);
      } else if (!validator.isEmail(email)) {
        NotificationManager.error("", "Please Enter Valid Email", 3000);
      } else if (pass === "") {
        NotificationManager.error("", "Please Enter Password", 3000);
      } else {
        setLoading(true);
        const resultAction = await dispatch(
          signIn({
            username: email,
            password: pass,
          })
        );
        if (signIn.fulfilled.match(resultAction)) {
          const employeeData = resultAction.payload;
          if (employeeData != null) {
            window.localStorage.setItem(
              "employeeData",
              JSON.stringify(employeeData)
            );
            dispatch(setEmployeeData(employeeData));
            navigate("/", { replace: true });
          }
        } else if (signIn.rejected.match(resultAction)) {
          NotificationManager.error(
            "",
            typeof resultAction.payload === "string"
              ? resultAction.payload.toUpperCase()
              : "Error Signing In",
            3000
          );
        }
        setLoading(false);
      }
    },
    [dispatch, navigate, email, pass]
  );

  return (
    <div className="signinWrapper">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="signinMain">
        <form className="loginForm" onSubmit={handleSubmit}>
          <img
            src={require("../../assests/Images/Logo_500x163.png")}
            className="logoImage"
            alt="Sansar Logo"
          />
          <div>
            <p className="introText">
              Enter your credentials to start your <br />
              experience
            </p>
          </div>
          <label
            className="inputLabel"
            htmlFor="email"
            style={{ color: "white" }}
          >
            E-mail Address
          </label>
          <input
            className="inputCss"
            placeholder="Enter Your Email"
            title="Email"
            type="text"
            value={email}
            data-component-id="email-input"
            onChange={onEmailChange}
          />
          <br />
          <label
            className="inputLabel"
            htmlFor="password"
            style={{ color: "white" }}
          >
            Password
          </label>
          <input
            className="inputCss"
            placeholder="Enter Your Password"
            title="Password"
            type="password"
            value={pass}
            data-component-id="password-input"
            onChange={onPasswordChange}
          />
          <br />
          <button
            className="loginButton"
            type="submit"
            data-component-id="login-button"
            onClick={handleSubmit}
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};
