import AppConfig from "../../config/app.config";
import axios from "axios";

const http = axios.create({
  baseURL: AppConfig.API.ENDPOINT,
});

const UserService = {
  /**
   * User Sign in api call
   * @param {object} signInData - the signin payload
   */
  signIn: (signInData) => {
    return http
      .post("/commonrestapi/m1342055", signInData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  getWorlds: () => {
    return (
      axios
        // .get("/atlas-api/v3/all?perPage=100") // Run in development
        .get("https://atlas-api.sansar.com/v3/all?perPage=100") // Run on device
        .then((res) => res.data)
        .catch((error) => ({ error }))
    );
  },
  createStreamSession: (payload) => {
    return (
      axios
        // .post("/try/CreateStreamSession", payload) // Run in development
        .post("https://try.sansar.com/api/CreateStreamSession", payload) // Run on device
        .then((res) => res.data)
        .catch((error) => ({ error }))
    );
  },
  getSignalResponse: (payload) => {
    return (
      axios
        // .post("/try/GetSignalResponse", payload) // Run in development
        .post("https://try.sansar.com/api/GetSignalResponse", payload) // Run on device
        .then((res) => res.data)
        .catch((error) => ({ error }))
    );
  },
};

export default UserService;
