import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectEmployeeData, setEmployeeData } from "../store/NavigationSlice";
import 'react-notifications/lib/notifications.css';
import "./app.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Signin } from "../views/Signin/Signin";
import Worlds from "../views/Worlds/Worlds";
import Demo from "../views/Demo/Demo";
import "@fontsource/roboto";
import { NotificationContainer } from "react-notifications";

const App = () => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(true);

  useEffect(() => {
    const initialize = () => {
      let employeeData = window.localStorage.getItem("employeeData");
      if (employeeData != null) {
        try {
          employeeData = JSON.parse(employeeData);
        } catch (err) {
          window.localStorage.removeItem("employeeData");
          employeeData = null;
        }
      }
      dispatch(setEmployeeData(employeeData));
    };

    initialize();
    setIsInitialized(true);
  }, [dispatch]);

  return isInitialized ? (
    <>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Worlds />
              </RequireAuth>
            }
          />
          <Route
            path="/signin"
            element={
              <RequireNoAuth>
                <Signin />
              </RequireNoAuth>
            }
          />
          <Route
            path="/demo"
            element={
              <RequireAuth>
                <Demo />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
    <NotificationContainer />
    </>
  ) : null;
};

function Layout() {
  const navigate = useNavigate();
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    if (employeeData == null) {
      navigate("/signin", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Outlet />;
}

function RequireNoAuth({ children }) {
  const employeeData = useSelector(selectEmployeeData);

  if (employeeData != null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={"/"} replace />;
  }

  return children;
}

function RequireAuth({ children }) {
  const employeeData = useSelector(selectEmployeeData);
  // let location = useLocation();

  if (employeeData == null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" replace />;
  }

  return children;
}

export default App;
